<!-- eslint-disable -->
<template>
  <base-material-card
      icon="mdi-clipboard-text"
      title="Voir la liste des colis"
      class="px-5 py-3"
    >
    <template v-slot:heading>
      <v-icon @click="reloadItems">mdi-reload</v-icon>
    </template>

    <v-row>
    <v-col cols="12" sm="2" @click="getDeliveries('Confirmé');getDeliveries2('Confirmé')">
      <base-material-stats-card
          class="base-card"
          color="#fbc02d"
          icon="mdi-message-alert"
          sub-text="Confirmé"
          :value="returned.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2" @click="getDeliveries('EnRoute');getDeliveries2('EnRoute')">
      <base-material-stats-card 
          class="base-card"
          color="purple"
          icon="mdi-truck-delivery"
          sub-text="EnRoute"
          :value="engoing.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2" @click="getDeliveries('Arrivé');getDeliveries2('Arrivé')">
      <base-material-stats-card
            class="base-card"
            color="#000080"
            icon="mdi-package-variant-closed"
            sub-text="Arrivé"
            :value="arrived.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2" @click="getDeliveries('Chargement');getDeliveries2('Chargement')">
      <base-material-stats-card
          class="base-card"
          color="#0096ff"
          icon="mdi-motion-pause-outline"
          sub-text="Chargement"
          :value="enhold.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2" @click="getDeliveries('Livrée');getDeliveries2('Livrée')">
      <base-material-stats-card
            class="base-card"
            color="primary"
            icon="mdi-package-variant-closed"
            sub-text="Livrée"
            :value="delivered.toString()"
        />
    </v-col>
    <!-- <v-col cols="12" sm="2">
      <base-material-stats-card
          color="gray"
          icon="mdi-briefcase-check-outline"
          title="Created"
          :value="created.toString()"
        />
    </v-col> -->
    </v-row>
    <!-- <div style="width:100%;text-align:right">
     <v-btn color="success" small depressed @click="showPostModal = true">Ajouter un livreur</v-btn>
    </div> -->
    <b-modal v-model="showPostModal" hide-footer size="lg">
      <template #modal-title>
        Détails 
      </template>
      <div style="width:100%;text-align:center"><span style="color:red">{{newDeliveryErrorMessage}}</span></div>
      <v-container class="py-0">
          <b-form @submit.prevent="addNewDelivery">
          <v-row>
          <v-col cols="12" md="3">
            <label><strong>Dépot départ:</strong> {{newDelivery.depot1.name}} </label>
          </v-col>
          <v-col cols="12" md="3">
            <label><strong>Wilaya:</strong> {{newDelivery.depot1.wilaya}} </label>
            <!-- <v-text-field required v-model="newDelivery.depot1.wilaya" label="Wilaya" class="purple-input"/> -->
          </v-col>
          <v-col cols="12" md="3">
            <label><strong>Communue:</strong> {{newDelivery.depot1.communue}} </label>
            <!-- <v-text-field required v-model="newDelivery.depot1.communue" label="Communue" class="purple-input"/> -->
          </v-col>
          <v-col cols="12" md="3">
            <label><strong>Adresse:</strong> {{newDelivery.depot1.address}} </label>
            <!-- <v-text-field required v-model="newDelivery.depot1.address" label="Adresse" class="purple-input"/> -->
          </v-col>
          <v-col cols="12" md="3">
            <!-- <label>Depot d'arrivée</label>
            <b-form-select required disabled v-model="newDelivery.depot2">
              <option v-for="wilaya in depots" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
            </b-form-select> -->
            <label><strong>Dépot d'arrivée:</strong> {{newDelivery.depot2.name}} </label>
          </v-col>
          <v-col cols="12" md="3">
            <label><strong>Wilaya:</strong> {{newDelivery.depot2.wilaya}} </label>
          </v-col>
          <v-col cols="12" md="3">
            <label><strong>Communue:</strong> {{newDelivery.depot2.communue}} </label>
            <!-- <v-text-field required v-model="newDelivery.depot2.communue" label="Communue" class="purple-input"/> -->
          </v-col>
          <v-col cols="12" md="3">
            <label><strong>Adresse:</strong> {{newDelivery.depot2.address}} </label>
            <!-- <v-text-field required v-model="newDelivery.depot2.address" label="Adresse" class="purple-input"/> -->
          </v-col>
          <v-col cols="12" md="4">
            <label><strong>N° de Colis:</strong> {{newDelivery.colis_numbers}} </label>
            <!-- <v-text-field required v-model="newDelivery.quantity" label="Quantité" class="purple-input"/> -->
          </v-col>
          <v-col cols="12" md="4">
            <label><strong>Quantité:</strong> {{newDelivery.quantity}} </label>
            <!-- <v-text-field required v-model="newDelivery.weight" label="Poids" class="purple-input"/> -->
          </v-col>
          <v-col cols="12" md="4">
            <label><strong>Poids:</strong> {{newDelivery.weight}} </label>
            <!-- <v-text-field required v-model="newDelivery.colis_numbers" label="N° de Colis" class="purple-input"/> -->
          </v-col>
          </v-row>
          <div style="height:200px;margin-top:30px;overflow-y:auto,overflow-x:hidden">
            <label><strong>Liste des colis sélectionné:</strong></label>
            <v-row v-for="colis in newDelivery.colis" :key="colis.tracking_id">
              <v-col cols="12" md="4">
                <label><strong>Tracking ID:</strong> {{colis.tracking_id}} </label>
              </v-col>
              <v-col cols="12" md="4">
                <label><strong>Marchendise:</strong> {{colis.content}} </label>
              </v-col>
              <v-col cols="12" md="4">
                <label><strong>Quantité:</strong> {{colis.quantity}} </label>
              </v-col>
            </v-row>
          </div>
          <v-col cols="12" class="text-right">
            <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
              Confirmer
            </v-btn>
          </v-col>
          </b-form>
      </v-container>
    </b-modal>
    <b-modal v-model="showScanner" @ok="getOutScanner" @close="getOutScanner" title='Scanner QR code barre'>
      <qrcode-stream @decode="onDecodeQR"></qrcode-stream>
    </b-modal>
    <b-modal v-model="show2" @ok="getpaid()" title='CONFIRMATION DE PAIEMENT'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer le paiement</p>

    </b-modal>
    <b-modal v-model="show3" @ok="deletePackage" title='CONFIRMATION DE SUPPRESSION'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer la suppression</p>
    </b-modal>
    <!-- <b-modal v-model="show">
      <h3>{{infoshow.tracking_id}} :{{infoshow.content}}</h3><p>Clients name : {{infoshow.client_name}}</p><p>Phone Number : {{infoshow.client_phone}}</p>
      <p v-for="histori in infoshow.history" :key="histori.message">***{{moment(histori.creation_date).format('MM/DD/YYYY HH:mm')}} : {{histori.message}}</p>
    </b-modal> -->
    <b-modal v-model="show" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
    <div class="d-block text-center" style="background-color:white">
      <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
        <h4>Section des dates</h4>
        <strong>Date de création: </strong>{{serialiseDate(packageDetail.creation_date)}}<br>
        <strong>Date d'expédition: </strong>{{serialiseDate(packageDetail.shipping_date)}}<br>
        <strong>Date de livraison: </strong>{{serialiseDate(packageDetail.delivery_date)}}<br>
        <hr>
          <h4>Section d'historique</h4>
          <p v-for="histori in infoshow.history" :key="histori.message"><strong>{{moment(histori.creation_date).format('DD/MM/YYYY HH:mm')}}: </strong> {{histori.message}}</p>
        <hr>
        <h4>Section d'infomation de client</h4>
        <strong>Nom complet de client: </strong>{{packageDetail.client_name}}<br>
        <strong>N° Téléphone de client: </strong>{{packageDetail.client_phone}}<br>
        <strong>Nom complet de receveur: </strong>{{packageDetail.receiver_name}}<br>
        <strong>N° Téléphone de receveur: </strong>{{packageDetail.receiver_phone}}<br>
        <strong>Contenu de colis: </strong>{{packageDetail.content}}<br>
        <strong>Prix de colis: </strong>{{packageDetail.colis_price}}<br>
        <strong>Prix de livraison: </strong>{{parseInt(packageDetail.delivery_price)}}<br>
        <strong>Prix Total: </strong>{{parseInt(packageDetail.total_price)}}<br>
      </div>
    </div>
    <b-button class="mt-3" style="color:white" block @click="hideModal">Fermer</b-button>
  </b-modal>
  <b-modal v-model="showChangable" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
      <div style="text-align:center;">
        <v-btn class="mr-3" style="color:white;background-color:#FBC02D" @click="makeChanges2('Confirmé')">Confirmé</v-btn>
        <v-btn class="mr-3" style="color:white;background-color:#8E24AA" @click="makeChanges2('EnRoute')">EnRoute</v-btn>
        <v-btn class="mr-3" style="color:white;background-color:#000080" @click="makeChanges2('Arrivé')">Arrivé</v-btn>
        <v-btn class="mr-3" style="color:white;background-color:#0096FF" @click="makeChanges2('Chargement')">Chargement</v-btn>
        <v-btn class="mr-3" style="color:white;background-color:green" @click="makeChanges2('Livrée')">Livrée</v-btn>
        <!-- <div class="mt-3">
          <multiselect required v-model="packageDetail.selected_driver" tag-placeholder="Chauffeur" placeholder="Entrer le nom de chauffeur" label="name" track-by="name" :options="drivers2" :multiple="false" :taggable="true"></multiselect>
        </div> -->
      </div>
    <hr>
  </b-modal>
  <b-modal v-model="showChangable2" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
      <div style="text-align:center;">
        <!-- v-if="packageDetail != null && ((packageDetail.driver_name!='' && packageDetail.driver_phone!='') || packageDetail.selected_driver!=-1) && packageDetail.fees!=0" -->
        <v-btn class="mr-3" style="color:white;background-color:#616161" @click="makeChanges2('Created')">Created</v-btn>
        <v-btn  class="mr-3" style="color:white;background-color:#FBC02D" @click="makeChanges2('Confirmé')">Confirmé</v-btn>
        <v-btn  class="mr-3" style="color:white;background-color:#0096FF" @click="makeChanges2('Chargement')">Chargement</v-btn>
        <v-btn  class="mr-3" style="color:white;background-color:#8E24AA" @click="makeChanges2('EnRoute')">EnRoute</v-btn>
        <v-btn  class="mr-3" style="color:white;background-color:#000080" @click="makeChanges2('Arrivé')">Arrivé</v-btn>
        <v-btn  class="mr-3" style="color:white;background-color:green" @click="makeChanges2('Livrée')">Livrée</v-btn>
      </div>
    <hr>
    <b-form >
      <!-- @submit.prevent="makeChanges" -->
      <div class="d-block text-center" style="background-color:white">
        <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_name" label="Nom de client" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_phone" label="N° Téléphone" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.receiver_name" label="Nom de receveur" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.receiver_phone" label="N° Téléphone de receveur" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <b-form-select required v-model="packageDetail.selected_driver">
                  <option v-for="driver in drivers" :key="driver.did" :value="driver.did" :class="(driver.available===true || packageDetail.selected_driver===driver.did ? 'color-green' : (driver.available === false ? 'color-red': ''))">
                    <!-- <span v-if="driver.available==false">&#x25CF; {{driver.name}} {{driver.phone}}</span> -->
                    <span>{{driver.name}} {{driver.phone}}</span>
                  </option>
                </b-form-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-textarea
                required
                class="purple-input"
                label="Plus de détails (Max 1000 caractères, sinon laisser vide)"
                v-model="packageDetail.description"
              />
            </v-col>
        </div>
      </div>
    </b-form>
  </b-modal>
  <v-row>
  <v-col cols="12" md="6">
    <span v-if="isError" style="color:red">Vous devez selectionner au moins un colis</span>
    <span v-if="isQrError" style="color:red">Ce numéro de suivi {{qrTracking}} n'est pas lié à votre dépôt ou bien n'existe pas</span>
  </v-col>
  <v-col cols="12" md="6">
    <v-row>
    <v-col cols="12" md="3">
      <v-btn color="success" small depressed @click="showScanner=true"><v-icon>mdi-qrcode-scan</v-icon>Scanner</v-btn>
    </v-col>
    <v-col cols="12" md="4">
    <b-form-select required v-model="selectedDepot">
      <option :value="{name:'Tout',email:null}">Tout</option>
      <option v-for="wilaya in depots" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
    </b-form-select>
    </v-col>
    <v-col cols="12" md="3">
    <v-btn v-if="!isSelected" color="success" small depressed @click="getDeliveries(null,true,selectedDepot.email)">Créer une livraison</v-btn>
    <v-btn v-else color="success" @click="showDelivery" small depressed>Appliquer</v-btn>
    </v-col>
    <v-col cols="12" md="2">
    <v-btn color="success" small depressed @click="getDeliveries();getDeliveries2()">Annuler</v-btn>
    </v-col>
    </v-row>
  </v-col>
  </v-row>
    <label><strong>Liste des sorties</strong></label>
    <vue-good-table
      :columns="columns"
      ref="main-table"
      :isLoading.sync="isLoading"
      :rows="rows"
      :select-options="{enabled : isSelected, selectOnCheckboxOnly: true}"
      @on-column-filter="filtering"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:5}"
      @on-row-click="RowClick">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
          <v-btn id="status-btn" v-if="props.row.status == 'Created'" depressed small color="#616161">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Confirmé'" depressed small color="#FBC02D">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Chargement'" depressed small color="#0096FF">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'EnRoute'" depressed small color="#8E24AA">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Arrivé'" depressed small color="#000080">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Livrée'" depressed small color="green">{{props.row.status}}</v-btn>
          <!-- <v-btn v-if="props.row.status == 'Arrived'" depressed small color="blue">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-ONGO'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-DLI'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-ARV'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned'" depressed small color="#D50000">{{props.row.status}}</v-btn> -->
          <v-icon v-if="props.row.status == 'Created'" id="history-btn" style="margin-left:10px;color:red">mdi-close-box</v-icon>
        </span>
        <span v-else-if="props.column.field == 'tracking_id'">
          <v-icon id='print-btn' style="margin-right:10px" color="primary">mdi-printer</v-icon>
          <v-btn id="tracking-id-btn" v-b-tooltip.hover :title="copyMessage" depressed small color="#37474F">{{props.row.tracking_id}}</v-btn>
          <v-icon v-b-tooltip.hover title="Livraison à domicile" v-if="props.row.delivery_type=='Home'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon>
          <v-icon v-b-tooltip.hover title="Livraison gratuit" v-if="props.row.free_delivered==true" style="margin-left:10px;margin-right:10px;" color="primary">mdi-currency-usd-off</v-icon>
          <!-- <v-icon v-if="props.row.delivery_type=='Stop Desk'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon> -->
          <v-btn id="pay-btn" v-if="props.row.status == 'Livrée' && props.row.posttoprovider == false && props.row.free_delivered == false && props.row.price != 0" class="float-right" depressed small color="red">NOT PAID</v-btn>
          <v-btn v-if="props.row.status == 'Livrée' && props.row.posttoprovider == true && props.row.free_delivered == false && props.row.price != 0" depressed small color="green" class="float-right">GET PAID</v-btn>
          <!-- <v-btn v-if="props.row. == 'Livrée' && props.row.posttoprovider == true" depressed small color="green">GET PAID</v-btn> -->
        </span>
        <span v-else-if="props.column.field == 'price'">
          <span>{{props.row.price}}</span>
          <v-icon v-if="props.row.changable == true" id="changable-btn" v-b-tooltip.hover title="Cliquer pour changer" style="margin-left:10px;margin-right:10px;" color="primary">mdi-cog</v-icon>
          <!-- <b-form-input v-else id="changable-input" type="number" v-model="props.row.price" @keydown.native="makeChanges"></b-form-input> -->
        </span>
        <span v-else>{{props.row[props.column.field]}}</span>
      </template>
    </vue-good-table>
    <label><strong>Liste des entrées</strong></label>
    <vue-good-table
      :columns="columns2"
      :rows="rows2"
      :isLoading.sync="isLoading"
      :select-options="{enabled : isSelected, selectOnCheckboxOnly: true}"
      @on-column-filter="filtering"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:5}"
      @on-row-click="RowClick2">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
          <v-btn id="status-btn2" v-if="props.row.status == 'Created'" depressed small color="#616161">{{props.row.status}}</v-btn>
          <v-btn id="status-btn2" v-if="props.row.status == 'Confirmé'" depressed small color="#FBC02D">{{props.row.status}}</v-btn>
          <v-btn id="status-btn2" v-if="props.row.status == 'Chargement'" depressed small color="#0096FF">{{props.row.status}}</v-btn>
          <v-btn id="status-btn2" v-if="props.row.status == 'EnRoute'" depressed small color="#8E24AA">{{props.row.status}}</v-btn>
          <v-btn id="status-btn2" v-if="props.row.status == 'Arrivé'" depressed small color="#000080">{{props.row.status}}</v-btn>
          <v-btn id="status-btn2" v-if="props.row.status == 'Livrée'" depressed small color="green">{{props.row.status}}</v-btn>
          <!-- <v-btn v-if="props.row.status == 'Arrived'" depressed small color="blue">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-ONGO'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-DLI'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-ARV'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned'" depressed small color="#D50000">{{props.row.status}}</v-btn> -->
          <v-icon v-if="props.row.status == 'Created'" id="history-btn" style="margin-left:10px;color:red">mdi-close-box</v-icon>
        </span>
        <span v-else-if="props.column.field == 'tracking_id'">
          <v-icon id='print-btn' style="margin-right:10px" color="primary">mdi-printer</v-icon>
          <v-btn id="tracking-id-btn" v-b-tooltip.hover :title="copyMessage" depressed small color="#37474F">{{props.row.tracking_id}}</v-btn>
          <v-icon v-b-tooltip.hover title="Livraison à domicile" v-if="props.row.delivery_type=='Home'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon>
          <v-icon v-b-tooltip.hover title="Livraison gratuit" v-if="props.row.free_delivered==true" style="margin-left:10px;margin-right:10px;" color="primary">mdi-currency-usd-off</v-icon>
          <!-- <v-icon v-if="props.row.delivery_type=='Stop Desk'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon> -->
          <v-btn id="pay-btn" v-if="props.row.status == 'Livrée' && props.row.posttoprovider == false && props.row.free_delivered == false && props.row.price != 0" class="float-right" depressed small color="red">NOT PAID</v-btn>
          <v-btn v-if="props.row.status == 'Livrée' && props.row.posttoprovider == true && props.row.free_delivered == false && props.row.price != 0" depressed small color="green" class="float-right">GET PAID</v-btn>
          <!-- <v-btn v-if="props.row. == 'Livrée' && props.row.posttoprovider == true" depressed small color="green">GET PAID</v-btn> -->
        </span>
        <span v-else-if="props.column.field == 'price'">
          <span>{{props.row.price}}</span>
          <v-icon v-if="props.row.changable == true" id="changable-btn" v-b-tooltip.hover title="Cliquer pour changer" style="margin-left:10px;margin-right:10px;" color="primary">mdi-cog</v-icon>
          <!-- <b-form-input v-else id="changable-input" type="number" v-model="props.row.price" @keydown.native="makeChanges"></b-form-input> -->
        </span>
        <span v-else>{{props.row[props.column.field]}}</span>
      </template>
    </vue-good-table>
  </base-material-card>

</template>

<script>
/* eslint-disable */
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'Dashboard',
  data () {
    return {
      newDeliveryErrorMessage: null,
      isLoading: false,
      isSelected : false,
      isError: false,
      isQrError:false,
      selectedDepot: {email:null,name:'Tout'},
      selectedWilaya: {},
      selectedCommunue: null,
      newDelivery: {
        depot1: {},
        depot2: {},
      },
      // searchTerm: '',
      showPostModal: false,
      moment: moment,
      copyMessage: 'Cliquer pour copier',
      provider: null,
      pemail: null,
      fuckoff: null,
      posts: [],
      welcoming: null,
      packages: [],
      status_list: ['Created', 'Livrée','All'],
      wilaya_list: ['Alger', 'Annaba', 'Bejaia','All','Mascara'],
      produit_list: ['Phone', 'Ibad', 'Laptop','All'],
      status_search: 'All',
      wilaya_search: 'All',
      produit_search: 'All',
      search: null,
      packageDetail: {},
      columns: [
        {
          label:'Tracking_Id',
          field:'tracking_id',
          filterOptions: {enabled:true,placeholder: 'Entrer le tracking',}
        },
        {
          label:'Marchandise',
          field:'content',
          filterOptions: {enabled:true,placeholder: 'Entrer Produit',}
        },
        {
          label:'Nom de l\'expéditeur',
          field:'client_name',
          filterOptions: {enabled:true,placeholder: 'Entrer le Client',}
        },
        {
          label:'Status',
          field:'status',
          filterOptions: {enabled:true,placeholder: 'Choisir le status',filterDropdownItems:['Created','Livrée','EnRoute','Arrived','Chargement','Returned','Returned-ARV','Returned-DLI','Returned-ONGO']}
        },
        {
          label:'Source',
          field:'from_wilaya',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Destination',
          field:'to_wilaya',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Prix',
          width: '120px',
          field:'price'
        }
      ],
      columns2: [
        {
          label:'Tracking_Id',
          field:'tracking_id',
          filterOptions: {enabled:true,placeholder: 'Entrer le tracking',}
        },
        {
          label:'Marchandise',
          field:'content',
          filterOptions: {enabled:true,placeholder: 'Entrer Produit',}
        },
        {
          label:'Nom de l\'expéditeur',
          field:'client_name',
          filterOptions: {enabled:true,placeholder: 'Entrer le Client',}
        },
        {
          label:'Status',
          field:'status',
          filterOptions: {enabled:true,placeholder: 'Choisir le status',filterDropdownItems:['Created','Livrée','EnRoute','Arrived','Chargement','Returned','Returned-ARV','Returned-DLI','Returned-ONGO']}
        },
        {
          label:'Source',
          field:'from_wilaya',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Destination',
          field:'to_wilaya',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Prix',
          width: '120px',
          field:'price'
        }
      ],
      rows: [],
      rows2: [],
      willayas: [],
      show: false,
      showChangable: false,
      showChangable2: false,
      infoshow: [],
      show2 : false,
      show3: false,
      tr_id2: null,
      delivered: null,
      returned: null,
      created: null,
      engoing: null,
      enhold: null,
      arrived:null,
      setset: null,
      wilayas:[],
      depots: [],
      drivers: [],
      showScanner: false,
      qrTracking:null,
    }

  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    // this.gettingwilayas()
    this.getWilayas()
    this.getPostCenters()
    // this.detectingprovider()
    this.getDeliveries()
    this.getStatusList()
    this.gettingdepots()
    this.getDeliveries2()
    this.loadDrivers()
  },
  methods: {
    reloadItems() {
      this.isQrError = false
      this.isError = false
      this.isLoading = true
      this.getDeliveries()
      this.getDeliveries2()
    },
    onDecodeQR(decodedString) {
      this.qrTracking = decodedString
      axios.post('get-colis-express',{
        auth_token: localStorage.getItem('auth_token'),
        tracking_id:this.qrTracking,
      }).then(response => {
        if (response.data.data.packages.length == 0) {
          this.rows = []
          axios.post('get-colis-express',{
            auth_token: localStorage.getItem('auth_token'),
            status_type : 'shipping',
            tracking_id:this.qrTracking,
          }).then(response => {
            if (response.data.data.packages.length == 0) {
              this.rows2 = []
              this.showScanner = false
              this.isQrError = true
            } else {
              this.rows2=response.data.data.packages
              this.rows=[]
              this.showScanner = false
            }
          })
        } else {
          this.rows=response.data.data.packages
          this.rows2 = []
          this.showScanner = false
        }
        
      })
    },
    getOutScanner() {
      this.showScanner = false
    },
    loadDrivers() {
      axios.post('depot-drivers', {
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        if (response.data.status == 'success') {
          this.drivers = [];
          this.drivers2 = [];
          for (let i = 0; i < response.data.data.delivery_men.length; i++) {
            if (response.data.data.delivery_men[i].approved == true) {
              this.drivers.push(response.data.data.delivery_men[i])
            }
          }
          for (let i = 0; i < response.data.data.drivers.length; i++) {
            if (response.data.data.drivers[i].approved == true) {
              response.data.data.drivers[i].id = response.data.data.drivers[i].pk
              this.drivers2.push(response.data.data.drivers[i])
            }
          }
        }
      })
    },
    showDelivery() {
      console.log(this.$refs["main-table"].selectedRows)
      if (this.$refs["main-table"].selectedRows.length > 0) {
        let weight = 0
        let quantity = 0
        for (let i in this.$refs["main-table"].selectedRows) {
          weight += parseInt(this.$refs["main-table"].selectedRows[i].weight)
          quantity += parseInt(this.$refs["main-table"].selectedRows[i].quantity)
        }
        this.showPostModal=true
        this.isError = false
        this.newDelivery = {
          depot1: this.getdepot(this.$refs["main-table"].selectedRows[0].depot1),
          depot2: this.getdepot(this.$refs["main-table"].selectedRows[0].depot2),
          weight: weight,
          quantity: quantity,
          colis_numbers: this.$refs["main-table"].selectedRows.length,
          colis: this.$refs["main-table"].selectedRows
        }
        console.log(this.newDelivery)
      } else {
        this.isError = true
      } 
    },
    getdepot(email) {
      for (let i in this.depots) {
        if (this.depots[i].email == email) {
          return this.depots[i]
        }
      }
      return null
    },
    gettingdepots(){
      axios.post('get-depots').then(response => {
          this.depots = response.data.data
      })
    },
    getWilayas() {
      axios.post('get-wilayas').then(response => {
        if (response.data.status == 'success') {
          this.wilayas = response.data.data
          let wilayas = []
          this.wilayas.forEach(element => {
            wilayas.push(element.name)
          });
          this.$set(this.columns[2].filterOptions, 'filterDropdownItems', wilayas);
          // this.columns[2].filterOptions.filterDropdownItems = wilayas
        }
      })
    },
    addNewDelivery() {
      axios.post('create-delivery-express', {
        auth_token: localStorage.getItem('auth_token'),
        delivery_info: this.newDelivery,
      }).then(response => {
        if (response.data.status == 'success') {
          this.showPostModal = false
          this.newDeliveryErrorMessage = null
        } else {
          this.newDeliveryErrorMessage = response.data.error_message
        }
      })
    },
    getPostCenters() {
        axios.post('get-post-centers').then(response => {
          if (response.data.status == 'success') {
            let names= []
            response.data.data.forEach(element=> {
              names.push(element.name)
            })
            this.$set(this.columns[4].filterOptions, 'filterDropdownItems', names);
          }
        })
      },
    getpaid(){
      axios.post('getpaid',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.tr_id2,
        setset: this.setset,
      }).then(response => {
        this.getDeliveries()
      })

    },
    copyTrackingId(row) {
      this.$clipboard(row.tracking_id);
      this.copyMessage = 'Copié!'
      setTimeout(() => {
        this.copyMessage = 'Cliquer pour copier'
      }, 2000);
    },
    deletePackage() {
      axios.post('deletecolis',{
          auth_token: localStorage.getItem('auth_token'),
          tr_id: this.packageDetail.tracking_id,
        }).then(response => {
          this.getDeliveries()
          this.getDeliveries2()
        })
    },
    makeChanges2(status) {
      axios.post('change-colis-express',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.packageDetail.tracking_id,
        status:status,
        selected_driver:this.packageDetail.selected_driver.id,
        client_name:this.packageDetail.client_name,
        client_phone:this.packageDetail.client_phone,
        receiver_name:this.packageDetail.receiver_name,
        receiver_phone:this.packageDetail.receiver_phone,
        description:this.packageDetail.description,
      }).then(response => {
        if (response.data.status == 'success')
          this.getDeliveries()
          this.getDeliveries2()
          this.showChangable2 = false
          this.showChangable = false
          
      })
    },
    makeChanges(status) {
      axios.post('provider-change-delivery',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.packageDetail.tracking_id,
        status:status,
      }).then(response => {
        if (response.data.status == 'success')
          this.getDeliveries()
          this.showChangable = false
      })
    },
    RowClick2(params){
      this.packageDetail = params.row
      if (params.event.target.id == 'status-btn2' || params.event.target.parentNode.id == 'status-btn2' || params.event.target.parentNode.parentNode.id == 'status-btn2' || params.event.target.parentNode.parentNode.parentNode.id == 'status-btn2') {
        this.showChangable2 = true
      } else if (params.event.target.id == "history-btn"){
        this.show3 = true
      } else if (params.event.target.id == 'print-btn' || params.event.target.parentNode.id == 'print-btn') {
        axios.post('print-pdf-express', {
          auth_token: localStorage.getItem('auth_token'),
          tracking_id: this.packageDetail.tracking_id
        }).then(response=> {
          if (response.data.status == 'success') {
            const link = document.createElement('a')
            link.target = '_blank'
            link.href = process.env.VUE_APP_MEDIA_URL+"document.pdf"
            link.setAttribute('download', "document.pdf")
            document.body.appendChild(link)
            link.click()
          }
        })
      }
      else if (params.event.target.id == 'tracking-id-btn' || params.event.target.parentNode.id == 'tracking-id-btn') {
        this.copyTrackingId(params.row)
      } else{
        this.show = true
        this.infoshow = params.row
      }
    },
    RowClick(params){
      this.packageDetail = params.row
      this.packageDetail.selected_driver = {
        name:params.row.driver_name,
        id:params.row.selected_driver
      }
      if (params.event.target.id == "history-btn"){
        this.show3 = true
      }
      else if (params.event.target.localName == "input" || params.event.target.className == "vgt-checkbox-col") {
      }
      else if (params.event.target.id == 'status-btn' || params.event.target.parentNode.id == 'status-btn' || params.event.target.parentNode.parentNode.id == 'status-btn' || params.event.target.parentNode.parentNode.parentNode.id == 'status-btn') {
        this.showChangable = true
      }
      else if (params.event.target.id == 'print-btn' || params.event.target.parentNode.id == 'print-btn') {
        axios.post('print-pdf-express', {
          auth_token: localStorage.getItem('auth_token'),
          tracking_id: this.packageDetail.tracking_id
        }).then(response=> {
          if (response.data.status == 'success') {
            const link = document.createElement('a')
            link.target = '_blank'
            link.href = process.env.VUE_APP_MEDIA_URL+"document.pdf"
            link.setAttribute('download', "document.pdf")
            document.body.appendChild(link)
            link.click()
          }
        })
      }
      else if (params.event.target.id == 'changable-btn') {
        this.showChangable = true
      }
      else if (params.event.target.id == 'tracking-id-btn' || params.event.target.parentNode.id == 'tracking-id-btn') {
        this.copyTrackingId(params.row)
      }
      else if (params.event.target.id == "pay-btn" || params.event.target.parentNode.id == 'pay-btn'){
        this.show2 = true
        this.tr_id2 = params.row.tracking_id
        this.setset = 'normal'
      }
      else{
        this.show = true
        this.infoshow = params.row
      }
    },
    gettingwilayas(){
      axios.post('get-wilayas').then(response => {

        for (let i=0;i<response.data.data.length;i++){
          this.willayas.push(response.data.data[i].name)
        }
        // this.columns[4].filterOptions.filterDropdownItems= this.willayas
        this.$set(this.columns[4].filterOptions, 'filterDropdownItems', this.willayas);
      })

    },
    filtering(params) {
      console.log(params)
    },
    getStatusList() {
      axios.post('get-status-list').then(response => {
        if (response.data.status == 'success') {
          this.items = response.data.data
        }
      })
    },
    handleModalHide(params) {
      this.show = false
      this.showChangable = false
    },
    hideModal(params) {
      this.show = false
      this.showChangable = false
    },
    serialiseDate(date) {
      if (date != null && date!= undefined)
        return moment(date).format('DD/MM/YYYY HH:mm')
      else
        return 'N/A'
    },
   detectingprovider(){
        axios.post('detectingprovider',{
          auth_token: localStorage.getItem('auth_token'),
        }).then(response => {
          this.provider = response.data.data.provider.name
          this.pemail = response.data.data.provider.email
          this.fuckoff = response.data.status
          for (let i=0;i<response.data.data.posts.length;i++){
            this.posts.push({code:response.data.data.posts[i].code,name:response.data.data.posts[i].name})
          }
        })
      },
    getDeliveries(status=null,notLinked=false, depot=null) {
      this.isError = false
      if (notLinked) {
        this.isSelected = true
      } else {
        this.isSelected = false
      }
      axios.post('get-colis-express',{
        auth_token: localStorage.getItem('auth_token'),
        status:status,
        not_linked:notLinked,
        depot:depot
      }).then(response => {
        this.rows=response.data.data.packages
        this.infoshow=response.data.data.packages
        this.delivered=response.data.data.Delivered
        this.returned=response.data.data.Returned
        this.created=response.data.data.Created
        this.engoing=response.data.data.OnGoing
        this.enhold=response.data.data.OnHold
        this.arrived=response.data.data.Arrived
      })
    },
    getDeliveries2(status=null) {
      axios.post('get-colis-express',{
        auth_token: localStorage.getItem('auth_token'),
        status_type : 'shipping',
        status:status
      }).then(response => {
        this.rows2=response.data.data.packages
      })
    },
    getingpackages(){
      axios.post('getingpackages',{
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        // console.log(response.data.data.packages)
        // for (let i=0;i<response.data.data.packages.length;i++){
        //   this.packages.push({
        //     package_id:response.data.data.packages[i].tracking_id,
        //     content:response.data.data.packages[i].content,
        //     status:response.data.data.packages[i].status,
        //     wilaya:response.data.data.packages[i].wilaya,
        //     client_name:response.data.data.packages[i].client_name,
        //     price:response.data.data.packages[i].price,

        //   })

        // }
        this.rows=response.data.data.packages
        this.infoshow=response.data.data.packages
        this.delivered=response.data.data.Livrée
        this.returned=response.data.data.Returned
        this.created=response.data.data.Created
        this.engoing=response.data.data.EnRoute
        this.enhold=response.data.data.Chargement
        this.arrived=response.data.data.Arrived
      })
    }
  },
}
</script>
<style>
.base-card:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.multiselect__option--selected {
  background: greenyellow !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>